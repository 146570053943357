import React , {useEffect, useState} from 'react';
import {useContentState } from '../../context';
import Header from '../HeaderSection/Header';

const AboutUs = props => {
  const contentDetails = useContentState();
  const [loadState, setLoadState] = useState(false);
  const [loadMessage, setLoadMessage] = useState('Creando tu espacio seguro ...')
  const [loadHeader, setLoadHeader] = useState('Cargando')
  const [loadButtonText, setLoadButtonText] = useState('')
  const [loadButtonLink, setLoadButtonLink] = useState('/login')
    return (
      <div className="inner inner-pages">
        <div className="main">
        <Header 
                    imageData={"/img/logo-white.png"} 
                    setLoadState={setLoadState} 
                    loadState ={loadState}
                    loadMessage =  {loadMessage}
                    loadHeader = {loadHeader} 
                    loadButtonText={loadButtonText}
                    loadButtonLink = {loadButtonLink
        }/>    
          <section id="home" className="section welcome-area  h-100vh overflow-hidden">
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="col-12 col-md-8">
                  <div className="welcome-intro">
                    <h1 className="text-white">{contentDetails.ThankYouSectionContent.heading}</h1>
                    <p className="text-white my-4">{contentDetails.ThankYouSectionContent.content}</p>
                    <a href="/" className="btn sApp-btn text-uppercase">{contentDetails.ThankYouSectionContent.btnText}</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  
}

export default AboutUs;