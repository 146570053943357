import React, { Component } from 'react';
import {useContentState } from '../../context';


const ScheduleInvite = props =>{  
  const contentDetails = useContentState();
        return (
            <section className="section inviteschedule-area overlay-dark ptb_100 ">
                <div className="container mt-0">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Download Text */}
                        <div className="download-text text-center">
                        <h2 className="text-white">{contentDetails.ScheduleInviteSection.heading}</h2>
                        <p className="text-white my-3 d-none d-sm-block">{contentDetails.ScheduleInviteSection.headingText}</p>
                        <p className="text-white my-3 d-block d-sm-none">{contentDetails.ScheduleInviteSection.headingTexttwo}</p>
                        {/* Store Buttons */}
                        <div className="button-group store-buttons d-flex justify-content-center">
                            <a href="/asesoria">
                            <img src={contentDetails.ScheduleInviteSection.playImg} alt="" />
                            </a>
                            <a href="/#">
                            </a>
                        </div>
                        <span className="d-inline-block text-white fw-3 font-italic mt-3">{contentDetails.ScheduleInviteSection.headingSlug}</span>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    
}

export default ScheduleInvite;