import React , {useEffect, useState} from 'react';
import Header from '../components/HeaderSection/Header';
import HeroSection from '../components/HeroSection/HeroOne';
//import Counter from '../components/CounterSection/Counter';
import FeatureSection from '../components/Features/FeatureOne';
import ServiceSection from '../components/ServiceSection/ServiceOne';
import DiscoverSection from '../components/DiscoverSection/DiscoverOne';
import Work from '../components/WorkSection/Work';
//import ScreenshotSection from '../components/ScreenshotSection/ScreenshotsOne';
import BrandbannerSection from '../components/BrandbannerSection/BrandbannerOne';

import ReviewSection from '../components/ReviewSection/ReviewOne';
import PricingSection from '../components/PricingSection/PricingOne';
import FaqSection from '../components/FaqSection/FaqTwo';
import ScheduleInvite from '../components/CompletedSection/ScheduleInvite';
//import Subscribe from '../components/SubscribeSection/Subscribe';
import Team from '../components/TeamSection/Team';
import ContactSection from '../components/ContactSection/Contact';
import FooterSection from '../components/FooterSection/Footer';

function ThemeOne(props){
 const [loadState, setLoadState] = useState(false);
 const [loadMessage, setLoadMessage] = useState('Creando tu espacio seguro ...')
 const [loadHeader, setLoadHeader] = useState('Cargando')
 const [loadButtonText, setLoadButtonText] = useState('')
 const [loadButtonLink, setLoadButtonLink] = useState('/login')

  return (
      <div>
          {/*====== Scroll To Top Area Start ======*/}
          <div id="scrollUp" title="Scroll To Top">
              <i className="fas fa-arrow-up" />
          </div>
          {/*====== Scroll To Top Area End ======*/}
          <div className="main">
              <Header 
                    imageData={"/img/logo-white.png"} 
                    setLoadState={setLoadState} 
                    loadState ={loadState}
                    loadMessage =  {loadMessage}
                    loadHeader = {loadHeader} 
                    loadButtonText={loadButtonText}
                    loadButtonLink = {loadButtonLink}/>
              <HeroSection />
              <FeatureSection />
              <BrandbannerSection/>
              <ServiceSection />
              <DiscoverSection />
              <Work />
              <ReviewSection />
              <PricingSection 
                    setLoadState={setLoadState} 
                    setLoadHeader = {setLoadHeader}
                    setLoadMessage ={setLoadMessage} 
                    setLoadButtonText={setLoadButtonText}
                    setLoadButtonLink ={setLoadButtonLink}
                    />
              <FaqSection />
              <Team />
              <ScheduleInvite />
              <ContactSection />
              <FooterSection />
          </div>
      </div>
  );

}

export default ThemeOne;