import React , {useEffect, useState} from 'react';

const FileSummary = ({
    setPsychologistsData,
    psychologistsData
    } )=> {
        console.log('---------FileSummary/psychologistsData.data(): ', psychologistsData);
        const [isLoaded, setLoaded] = useState(false);
        const [test, setTest] = useState(false);

        useEffect(() => {
            setLoaded( psychologistsData);
            setTest( 4.5);

          }, [isLoaded]);

          let renderSwitch =param => {
            console.log('renderSwitch', param)
            if(param > 0 && param<= .5) {
                return (
                    <div className="review-icon">
                        <i className={"fas fa-star-half-alt text-warning"} />
                    </div>
                );
            } else if(param > .5 && param<= 1) {
                        return (
                            <div className="review-icon">
                                <i className={"fas fa-star text-warning"} />
                            </div>
                        );
                } else if(param > 1 && param<= 1.5) {
                    return (
                        <div className="review-icon">
                            <i className={"fas fa-star text-warning"} />
                            <i className={"fas fa-star-half-alt text-warning"} />
                        </div>
                    );
            } else if(param > 1.5 && param<= 2) {
                return (
                    <div className="review-icon">
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                    </div>
                );
        } else if(param > 2&& param<= 2.5) {
            return (
                <div className="review-icon">
                    <i className={"fas fa-star text-warning"} />
                    <i className={"fas fa-star text-warning"} />
                    <i className={"fas fa-star-half-alt text-warning"} />
                </div>
            );
            } else if(param > 2.5 && param<= 3) {
                return (
                    <div className="review-icon">
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />

                    </div>
                );
            } else if(param > 3 && param<= 3.5) {
                return (
                    <div className="review-icon">
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star-half-alt text-warning"} />
                    </div>
                );
            } else if(param > 3.5 && param<= 4) {
                return (
                    <div className="review-icon">
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                    </div>
                );
            } else if(param > 4 && param<= 4.5) {
                return (
                    <div className="review-icon">
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star-half-alt text-warning"} />
                    </div>
                );
            } else if(param > 4.5 && param<= 5) {
                return (
                    <div className="review-icon">
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                        <i className={"fas fa-star text-warning"} />
                    </div>
                );
            } else{
                            return (
                                <div className="review-icon">
                                    Score no valido
                                </div>
                            );
                        }

                    };


          return (
                    <div className="row">
                        {psychologistsData.map((item, idx) => {
                            return(
                                <div key={`ro_${idx}`} className="col-12 col-md-6 col-lg-4 mb-lg-5 res-margin">
                                    {/* Single Review */}
                                    <div className="single-review card">
                                        {/* Card Top */}
                                        <div className="card-top p-4">
                                            <div className="review-icon">
                                            {renderSwitch(item.data().score)}

                                            </div>
                                            <h4 className="text-primary mt-4 mb-3">{item.data().name}</h4>
                                            {/* Review Text */}
                                            <div className="review-text">
                                                <p>{item.data().description}</p>
                                            </div>
                                            {/* Quotation Icon */}
                                            <div className="quot-icon">
                                                <img className="avatar-md" src={item.data().logo} alt="" />
                                            </div>
                                        </div>
                                        {/* Reviewer */}
                                        <div className="reviewer media bg-gray p-4">
                                            {/* Reviewer Thumb */}
                                            <div className="reviewer-thumb">
                                                <img className="avatar-lg radius-100" src={item.data().img} alt="" />
                                            </div>
                                            {/* Reviewer Media */}
                                            <div className="reviewer-meta media-body align-self-center ml-4">
                                                <h5 className="reviewer-name color-primary mb-2">{item.data().text1}</h5>
                                                <h6 className="text-secondary fw-6">{item.data().text2}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

        );
}

export default FileSummary;