import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const firebase = require("firebase/app");
require("firebase/firestore");
require('firebase/analytics')

var config = {
  apiKey: "AIzaSyDZH68g28xPWtGBXtkjWtP1ozL_qnev9s8",
  authDomain: "amaruapp-d9102.firebaseapp.com",
  databaseURL: "https://amaruapp-d9102-default-rtdb.firebaseio.com",
  projectId: "amaruapp-d9102",
  storageBucket: "amaruapp-d9102.appspot.com",
  messagingSenderId: "297964508041",
  appId: "1:297964508041:web:dbca3f1f31a72f14f84758",
  measurementId: "G-C64BKDMZMD"
};


firebase.initializeApp(config)
firebase.analytics();


const app = (
    <App />
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();