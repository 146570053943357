
import { loadStripe } from '@stripe/stripe-js';

export async function signupUser(dispatch, {email, password, passwordConfirm, name}) {
  const firebase = require("firebase");

  try {
    dispatch({ type: 'REQUEST_SIGNUP' });
    if(password === passwordConfirm){    
      await firebase // Note that we are using two components from firebase. First we are using the data base and secod the Authenticator
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          authRes => {
            const userObj = {
              user: authRes.user.email,
              name: name, 
              token: authRes.user.uid,
            }; //TODO: Add more info to user profile

            console.log("accounts/Signup/submitSignup -> authRes.user: ",authRes.user )
            firebase 
              .firestore()
              .collection("signup_form")
              .add(userObj)
              .then(()=> {        
                console.log("accounts/Signup/submitSignup -> userObj; ",userObj );
                dispatch({ type: 'SIGNUP_SUCCESS', payload: userObj });
                // Save information in localStorage to acess signedIn functionalities
                localStorage.setItem('token', authRes.user.uid);
                localStorage.setItem('email', authRes.user.email);

                }, dbError => {
                console.log("accounts/Signup/submitSignup -> DataBase Error:" ,dbError);
                //this.setState({signupError: "Failed to add user DB"});
              })
          }, authError => {
            console.log("accounts/Signup/submitSignup -> Authentication Error:", authError);
            //this.setState({signupError: "Failed to add user Authenticator"}); 
            }
        )
        return 'El usuario ha sido creado';
    }else{
      throw new Error('La contraseña que ingresaste no coincide con la contraseña de confirmación.');
    }
  }catch (error) {
    dispatch({ type: 'SIGNUP_ERROR', error: error });
    console.log('REQUEST_SIGNUP: ', error);
    return 'Error al crear el usuario, favor de validar la información y volver a intentar';
  }
}


export async function uploadForm(dispatch, {formData}) {
  const firebase = require("firebase");
  try {
    dispatch({ type: 'REQUEST_UPLOADFORM' });
    console.log("actions/uploadForm -> formData: ",formData)
  
    await firebase 
      .firestore()
      .collection(formData.collectionName)
      .add(formData)
      .then(()=> {        
        dispatch({ type: 'UPLOADFORM_SUCCESS', payload: formData });
        }, dbError => {
        console.log("Forms/Steeper/uploadForm -> DataBase Error:" ,dbError);
        //this.setState({signupError: "Failed to add user DB"});
      })
  }catch (error) {
    dispatch({ type: 'UPLOADFORM_ERROR', error: error });
    console.log('REQUEST_UPLOADFORM: ', error);
    return;
  }
}


export async function doesNotHaveSubs(dispatch, {user}) {
  console.log('actions/doesNotHaveSubs -> token', user);
  
  const firebase = require("firebase");
  try {
    dispatch({ type: 'REQUEST_SUBSCRIPTIONS' });  
    const usersSnapshot = await firebase
      .firestore()
      .collection("users")
      .where('email', 'in', [user.email])
      //.collection('subscriptions')
      .get()
      //.where('subscriptions', 'array-contains', 'trailing')
    const data = usersSnapshot.docs
      .map(_doc => _doc.data());

    //const test = data.map(_doc => _doc.data().email).includes(user.email);


    console.log('test:  ' ,  data)
    //console.log('actions/doesNotHaveSubs: -> data: ', test );

    return data;

  }catch (error) {
    dispatch({ type: 'SUBSCRIPTIONS_REQUEST_ERROR', error: error });
    console.log('SUBSCRIPTIONS_REQUEST_ERROR: ', error);
    return;
  }

}
//-------------------------STRIPE TS FILES TRADUCTION-------------------


export async function sendToCustomerPortal(dispatch) {
    // had to update firebase.app().functions() to firebase.default.functions() and
    // removed the region from the functions call (from stripe firebase extension docs)
    try {
        dispatch({ type: 'REQUEST_STRIPEPORTAL' });
    const firebase = require("firebase");
    const functionRef = firebase.default
      .functions()
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
    }catch (error) {
        dispatch({ type: 'STRIPEPORTAL_ERROR', error: error });
        console.log('STRIPEPORTAL_ERROR: ', error);
        return;
      }
  }

  export async function getPsychologistsInfo(dispatch) {
    // Retrives the psychologist information stored in firabse
    try {
        dispatch({ type: 'REQUEST_PSYCHOLOGISTSINFO' });
        const firebase = require("firebase");
        const psychologistsSnapshot = await firebase
            .firestore()
            .collection("psychologists")
            .get();
        console.log('actions/getPsychologistsInfo: ', psychologistsSnapshot.docs)
        return psychologistsSnapshot

    }catch (error) {
        dispatch({ type: 'PSYCHOLOGISTSINFO_ERROR', error: error });
        console.log('PSYCHOLOGISTSINFO_ERROR: ', error);
        return;
      }
  }

  export async function getPsychologistInfo(dispatch, {path_psicologa}) {
    // Retrives the psychologist information stoered in firabse
    try {
        dispatch({ type: 'REQUEST_PSYCHOLOGISTINFO' });
        const firebase = require("firebase");
        const psychologistSnapshot = await firebase
            .firestore()
            .collection('psychologists')
            .where('profilePath', '==', path_psicologa)  
            .get()
        console.log('actions/psychologistSnapshot: ', psychologistSnapshot)
        return psychologistSnapshot

    }catch (error) {
        dispatch({ type: 'PSYCHOLOGISTINFO_ERROR', error: error });
        console.log('PSYCHOLOGISTINFO_ERROR: ', error);
        return;
      }
  }


  export async function getBlogInfo(dispatch, {blogPath}) {
    // Retrives the psychologist information stoered in firabse
    try {
        dispatch({ type: 'REQUEST_BLOGINFO' });
        const firebase = require("firebase");
        const blogSnapshot = await firebase
            .firestore()
            .collection('Blogs')
            .where('blogPath', '==', blogPath)  
            .get()
        console.log('actions/blogSnapshot: ', blogSnapshot)
        return blogSnapshot

    }catch (error) {
        dispatch({ type: 'BLOGINFO_ERROR', error: error });
        console.log('BLOGINFO_ERROR: ', error);
        return;
      }
  }

  export async function getCirculoInfo(dispatch, {path_circulo}) {
    // Retrives the psychologist information stoered in firabse
    try {
        dispatch({ type: 'REQUEST_CIRCULOINFO' });
        const firebase = require("firebase");
        const circuloSnapshot = await firebase
            .firestore()
            .collection('circulos')
            .where('profilePath', '==', path_circulo)  
            .get()
        console.log('actions/circuloSnapshot: ', circuloSnapshot)
        return circuloSnapshot
    }catch (error) {
        dispatch({ type: 'CIRCULOINFO_ERROR', error: error });
        console.log('CIRCULOINFO_ERROR: ', error);
        return;
      }
  }

  export async function getBlogsInfo(dispatch) {
    // Retrives the psychologist information stored in firabse
    try {
        dispatch({ type: 'REQUEST_BLOGSINFO' });
        const firebase = require("firebase");
        const blogsSnapshot = await firebase
            .firestore()
            .collection("Blogs")
            .get();
        console.log('actions/getBlogsInfo: ', blogsSnapshot.docs)
        return blogsSnapshot

    }catch (error) {
        dispatch({ type: 'BLOGSINFO_ERROR', error: error });
        console.log('BLOGSINFO_ERROR: ', error);
        return;
      }
  }

  export async function getCirculosInfo(dispatch) {
    // Retrives the psychologist information stored in firabse
    try {
        dispatch({ type: 'REQUEST_CIRCULOSINFO' });
        const firebase = require("firebase");
        const blogSnapshot = await firebase
            .firestore()
            .collection("circulos")
            .get();
        console.log('actions/getCirculosInfo: ', blogSnapshot.docs)
        return blogSnapshot

    }catch (error) {
        dispatch({ type: 'CIRCULOSINFO_ERROR', error: error });
        console.log('CIRCULOSSINFO_ERROR: ', error);
        return;
      }
  }

  export async function sendToCheckout(dispatch, {checkoutData}) {
  const firebase = require("firebase");
  try {
    dispatch({ type: 'REQUEST_STRIPECHECKOUT' });
    console.log("actions/sendToCheckout -> checkoutData: ",checkoutData)
    var productId = '';

    if(checkoutData.productName === 'therapy_1'){
      productId = 'price_1JMHzxJPuVUMtWpDXSTXhUl3';
    }else if  (checkoutData.productName === 'therapy_4') {
       productId = 'price_1JMHzxJPuVUMtWpDz6q0DRVz';
    }else if (checkoutData.productName === 'therapy_8'){
       productId = 'price_1JMHzxJPuVUMtWpDkmfhKj0e';
    }else if (checkoutData.productName === 'test'){
        productId = 'price_1Jj7RbJPuVUMtWpDE6NdrRy9'
    }else{
      console.log('ERROR: Invalid productName')
    }
    await firebase 
      .firestore()
      .collection('users')
      .doc(checkoutData.token)
      .collection('checkout_sessions')
      .add({
        price: productId,  
        success_url:  'https://amaru.mx/pago-completado', // return user to this screen on successful purchase
        cancel_url: 'https://amaru.mx/pago-cancelado', // TODO: Create component
        email: checkoutData.email
      })
      .then((docRef) => {
        // Wait for the checkoutSession to get attached by the extension
          docRef.onSnapshot(async (snap) => {
            const { error, sessionId } = snap.data();
            if (sessionId) {
                  // We have a session, let's redirect to Checkout
                  // Init Stripe
                  const stripe = await loadStripe(
                    'pk_live_51HEtCsJPuVUMtWpDVpf4xWtkoUAvfFJDpn08heooIJYwcwusWdybcW4nHjT3GCT2LoUmQKkD4FUGiBLYkIcEKppb00tQanCe9q' //* todo enter your public stripe key here
                  );
                  console.log(`redirecting`);
                  await stripe.redirectToCheckout({ sessionId });

                  //console.log("actions/stripecheckout -> payload; ",sessionId );
                  //dispatch({ type: 'STRIPECECKOUT_SUCCESS', payload: sessionId });
                  //localStorage.setItem('stripesession', sessionId);
            }
          });


        }, dbError => {
          console.log("Forms/Steeper/uploadForm -> DataBase Error:" ,dbError);
          //this.setState({signupError: "Failed to add user DB"});
        });
  }catch (error) {
    dispatch({ type: 'STRIPECHECKOUT_ERROR', error: error });
    console.log('STRIPECHECKOUT_ERROR: ', error);
    return;
  }
}



export async function loginUser(dispatch, {email, password}) {
  const firebase = require("firebase");
	try {
    dispatch({ type: 'REQUEST_LOGIN' });
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(
        authRes => {
          const userObj = {
            user: authRes.user.email,
            token: authRes.user.uid,
          };
          console.log('actions/loginUser -> email:', email, 'password: ', password, 'userObj: ', userObj );

          if (userObj.user) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: userObj });
            localStorage.setItem('token', authRes.user.uid);
            localStorage.setItem('email', authRes.user.email);


            return userObj;
          }
        }, authError => {
          console.log("accounts/Signup/submitSignup -> Authentication Error:", authError);
          //this.setState({signupError: "Failed to add user Authenticator"}); 
        }
      );
	} catch (error) {
		dispatch({ type: 'LOGIN_ERROR', error: error });
		console.log('LOGIN_ERROR', error);
    return;
	}
}

export async function logout(dispatch) {
	dispatch({ type: 'LOGOUT' });
	localStorage.removeItem('token');
	localStorage.removeItem('email');
    console.log('TEST TEST 1318381309 ')
}


export async function userExist(dispatch, {email, password}) {
  const firebase = require("firebase");
  try {
		dispatch({ type: 'REQUEST_USEREXIST' });
    const userExist = async()=>{
      const usersSnapshot = await firebase
        .firestore()
        .collection("users")
        .get();
      const exist = usersSnapshot.docs
        .map(_doc => _doc.data().email)
        .includes(email);
      //this.setState({serverError: !exists});
      return exist;
    }
  }catch (error) {
    dispatch({ type: 'USEREXIST_ERROR', error: error });
    console.log('USEREXIST_ERROR: ', error);
  }
}