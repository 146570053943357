import Header from '../HeaderSection/Header';
import Breadcrumb from '../Filesystem/Breadcrumb';
import PricingSection from '../PricingSection/PricingOne';
import FaqSection from '../FaqSection/FaqTwo'
import FooterSection from '../FooterSection/Footer';
import React , {useEffect, useState} from 'react';
import {useContentState } from '../../context';

const Pricing = props => {
    const [loadState, setLoadState] = useState(false);
    const [loadMessage, setLoadMessage] = useState('Creando tu espacio seguro ...')
    const [loadHeader, setLoadHeader] = useState('Cargando')
    const [loadButtonText, setLoadButtonText] = useState('')
    const [loadButtonLink, setLoadButtonLink] = useState('/login')
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
        <Header 
                    imageData={"/img/logo-white.png"} 
                    setLoadState={setLoadState} 
                    loadState ={loadState}
                    loadMessage =  {loadMessage}
                    loadHeader = {loadHeader} 
                    loadButtonText={loadButtonText}
                    loadButtonLink = {loadButtonLink
                }/>    
          <Breadcrumb title="Precios" />
          <PricingSection 
                    setLoadState={setLoadState} 
                    setLoadHeader = {setLoadHeader}
                    setLoadMessage ={setLoadMessage} 
                    setLoadButtonText={setLoadButtonText}
                    setLoadButtonLink ={setLoadButtonLink}
            />
          <FaqSection />
          <FooterSection />
        </div>
      </div>
    );
  
}

export default Pricing;