import React  from 'react';
import {useContentState } from '../../context';

const Work = props => {
  const contentDetails = useContentState();
    return (
      <section className="section work-area bg-overlay overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              {/* Work Content */}
              <div className="work-content text-center">
                <h2 className="text-blue">{contentDetails.WorkSectionContent.heading}</h2>
                <p className="text-blue my-3 mt-sm-4 mb-sm-5">{contentDetails.WorkSectionContent.headingText}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {contentDetails.WorkSectionContent.workData.map((item, idx) => {
              return(
                <div key={`w_${idx}`} className="col-12 col-md-4">
                {/* Single Work */}
                <div className="single-work text-center p-3">
                  {/* Work Icon */}
                  <div className="work-icon">
                    <img className="avatar-md" src={item.image} alt="" />
                  </div>
                  <h3 className="text-blue py-3">{item.title}</h3>
                  <p className="text-blue">{item.content}</p>
                </div>
              </div>
              );
            })}
          </div>
          <div className="text-center top-buffer-3">
              <a href="/asesoria" className="landing-button-dark btn mt-1 ">{contentDetails.DiscoverSectionContent.button}</a>
            </div>

        </div>
      </section>
    );
  
}

export default Work;