import React , {useEffect, useState} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from './Breadcrumb';
import PsychologystSidebar from './PsychologistSidebar';
import PsychologistDetails from './PsychologistDetails';
import Schedule from '../Schedule/Schedule';
import FooterSection from '../FooterSection/FooterTwo';
import {useContentState } from '../../context';
import { useParams } from 'react-router-dom';
import { getPsychologistInfo, useAuthDispatch } from '../../context';

const PsychologistProfile= props => {
    const contentDetails = useContentState();
    const [loadState, setLoadState] = useState(false);
    const [loadMessage, setLoadMessage] = useState('Creando tu espacio seguro ...')
    const [loadHeader, setLoadHeader] = useState('Cargando')
    const [loadButtonText, setLoadButtonText] = useState('')
    const [loadButtonLink, setLoadButtonLink] = useState('/login')

    let { path_psicologa } = useParams();
    const [psychologistData, setPsychologistData] = useState(false);
    const dispatch = useAuthDispatch();

    useEffect(() => {
        // Update the document title using the browser API
        const data = async() =>{
            try { 
                console.log('PsychologistsProfile-> getPsychologistInfo/ path_psicologa' ,path_psicologa);

                const  response = await getPsychologistInfo(dispatch, {path_psicologa});
                console.log('PsychologistsProfile-> getPsychologistInfo/ response' ,response.docs);
                setPsychologistData(response.docs);
                return response
            } catch (error) {
                console.log('Error: PsychologistProfile-> pyschologystData ' ,error);
            }
        } 
        const response  = data();
      },[]);

        return (
            <div className="blog blog-right">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <Breadcrumb  />
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">

                                <div className="col-12 col-lg-8">
                                    {psychologistData ?
                                        <PsychologistDetails 
                                        setPsychologistData={setPsychologistData} 
                                        psychologistData={psychologistData} />
                                        :   null
                                    }
                                </div>

                                <div className="col-12 col-lg-4">
                                    {psychologistData ?
                                        <PsychologystSidebar 
                                        setPsychologistData={setPsychologistData} 
                                        psychologistData={psychologistData} />
                                        :   null
                                    }
                                </div>


                            </div>

                            <div className="row">

                                <div className="col-12 ">
                                    {psychologistData ?
                                        <Schedule
                                        setPsychologistData={setPsychologistData} 
                                        psychologistData={psychologistData} />
                                        :   null
                                    }
                                </div>

                            </div>

                        </div>
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
export default PsychologistProfile;