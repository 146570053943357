import React, {  useState, useEffect } from 'react';
import { useAuthDispatch, useAuthState, useContentState, sendToCheckout } from '../../context';

const PricingSection = ({
    //State conected to header
    setLoadState,
    setLoadMessage,
    setLoadHeader,
    setLoadButtonLink,
    setLoadButtonText
}) => {
  const contentDetails = useContentState();
  const authState = useAuthState();
  const dispatch = useAuthDispatch();
  const [productName, setProductName] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');

    console.log('TEST', productName)
    if (productName === ''){ //First Load 
        console.log('PricingOne/useEffect -> Loading window');
    }else{ // Product Selected =- Butom clicked
        if(token){
             setLoadMessage(contentDetails.PricingSectionContent.loadMessage_signed);
             setLoadHeader(contentDetails.PricingSectionContent.loadHeader_signed);
             setLoadState(true);
             start_payment();
        }  else{ // User is not logged in
            console.log('PricingOne/start_payment->  Need authentication before making payment')
             setLoadMessage(contentDetails.PricingSectionContent.loadMessage_notSigned);
             setLoadHeader(contentDetails.PricingSectionContent.loadHeader_notSigned);
             setLoadButtonText(contentDetails.PricingSectionContent.loadButtonText);
             setLoadButtonLink(contentDetails.PricingSectionContent.loadButtonLink);
             setLoadState(true);
        }
    
    }
  }, [productName]);


  const start_payment = async() =>{
      //e.preventDefault();
    console.log('start_payment/productName' , productName)

        const checkoutData = {
        collectionName: 'StripeForm',  //This Variable needs to be chnaged for each component and Firebase Collection
        productName: productName,  
        token: authState.token, 
        email: authState.email 

        }

        console.log('HeroOne/start_payment_1 -> checkoutData: ', checkoutData)
        try { 
        let response = await sendToCheckout(dispatch, {checkoutData});
        //history.push('/')
        } catch (error) {
        console.log('Error: testinguser-> sendToCheckout ' ,error);
        }
  } 

    return (
      <section id="pricing" className="section price-plan-area bg-gray overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2>{contentDetails.PricingSectionContent.heading}</h2>
                <p className="d-none d-sm-block mt-4">{contentDetails.PricingSectionContent.headingText}</p>
                <p className="d-block d-sm-none mt-4">{contentDetails.PricingSectionContent.headingTexttwo}</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-lg-12">
              <div className="row price-plan-wrapper">
                {contentDetails.PricingSectionContent.pricingData.map((item, idx) => {
                  return(
                    <div key={`p_${idx}`} className="col-12 col-md-4 mt-3">
                      {/* Single Price Plan */}
                      <div className="single-price-plan text-center p-5">
                        {/* Plan Thumb */}
                        <div className="plan-thumb">
                          <img className="avatar-lg" src={item.planImage} alt="" />
                        </div>
                        {/* Plan Title */}
                        <div className="plan-title my-2 my-sm-3">
                          <h3 className="text-uppercase">{item.planTitle}</h3>
                        </div>
                        {/* Plan Price */}
                        <div className="plan-price pb-2 pb-sm-3">
                          <h1 className="color-primary"><small className="fw-7">{item.priceSub}</small>{item.planPrice}</h1>
                        </div>
                        {/* Plan Description */}
                        <div className="plan-description">
                          <ul className="plan-features">
                            <li className="border-top py-3">{item.li_1}</li>
                            <li className="border-top py-3">{item.li_2}</li>
                            <li className="border-top border-bottom py-3">{item.li_3}</li>

                            
                          </ul>
                        </div>
                        {/* Plan Button */}
                        <div className="plan-button" 
                          onClick={() => {     
                            setProductName(item.productName);
                            }}>
                          <a  className="btn mt-4">{item.planBtn}</a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-5">
            <p className="text-body pt-4 fw-5">{contentDetails.PricingSectionContent.text} <a href="/contact">{contentDetails.PricingSectionContent.textLink}</a></p>
          </div>
        </div>



      </section>
    );
  
}

export default PricingSection;