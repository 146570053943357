import React , {useEffect, useState} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from './Breadcrumb';
import CirculoDetails from './CirculoDetails';
import FooterSection from '../FooterSection/FooterTwo';
import {useContentState } from '../../context';
import { useParams } from 'react-router-dom';

const BlogProfile = props => {
    const contentDetails = useContentState();
    const [loadState, setLoadState] = useState(false);
    const [loadMessage, setLoadMessage] = useState('Creando tu espacio seguro ...')
    const [loadHeader, setLoadHeader] = useState('Cargando')
    const [loadButtonText, setLoadButtonText] = useState('')
    const [loadButtonLink, setLoadButtonLink] = useState('/login')

    let { blogPath } = useParams();

    console.log('}}}}}}}}}}}}}}}}}blogPath:', blogPath)
        return (
            <div className="blog blog-right">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <Breadcrumb title="Blog Details - Right Sidebar" />
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">
                                THIS IS THE PRFILE FOR {blogPath}
                                <div className="col-12 col-lg-9">
                                    <CirculoDetails />
                                </div>
                                <div className="col-12 col-lg-3">
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
export default BlogProfile;