import React, {  useState, useEffect } from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from './Breadcrumb';
import FooterSection from '../FooterSection/FooterTwo';
import { getPsychologistsInfo, useAuthDispatch } from '../../context';
import CirculoSummary from './CirculoSummary';

const CirculoIndex = props =>  {
        //TODO: Change useState to pass CirculoData rather than psychologist info.
        const [psychologistsData, setPsychologistsData] = useState(false);
        const dispatch = useAuthDispatch();

        useEffect(() => {
            // Update the document title using the browser API
            const data = async() =>{
                try { 
                    const  response = await getPsychologistsInfo(dispatch);
                    console.log('PsychologistsIndex-> getPsychologistsInfo/ response' ,response.docs);
                    setPsychologistsData(response.docs);
                    console.log('PsychologistsIndex-> getPsychologistsInfo/psychologistsData ' ,psychologistsData);
                    return response
                } catch (error) {
                    console.log('Error: PsychologistsIndex-> pyschologystsData ' ,error);
                }
            } 
            const response  = data();
          },[]);

          console.log('---------Psychologists/psychologistsData.data(): ', psychologistsData);

        
            
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <Breadcrumb title="Reviews" />
                    <section className="review-area ptb_100">

                    <div className="container">
                        {psychologistsData ?
                            <CirculoSummary 
                            setBlogsData={setPsychologistsData} 
                            psychologistsData={psychologistsData} />
                        :   null
                        }
                    </div>


                    </section>
                    <FooterSection />
                </div>
            </div>
        );
}

export default CirculoIndex;