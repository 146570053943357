import React , {useEffect, useState} from 'react';
import { logout, sendToCustomerPortal, useAuthDispatch } from '../../context';
import LoadingSection from '../Loading/LoadingOne';

const Header = ({
    setLoadState,
    loadState,
    loadHeader,
    loadMessage,
    loadButtonText,
    loadButtonLink
    }) => {
    const [isLoggedIn, setIsLoggedIn] = useState('');
    const dispatch = useAuthDispatch();
  
    const handleClose = () => {
        setLoadState(false);
    };

    useEffect(() => {
        setIsLoggedIn( localStorage.getItem('token'));
      }, [isLoggedIn]);
    
      useEffect(() => {
        console.log('EXECUTE: Header/setMessage: ',  loadMessage)
      }, [loadMessage]);


    const sendToPortal = async() =>{
        //e.preventDefault();
        ;
     try { 
       setTimeout(function(){
            setLoadState(true);
        }, 2000);
       let response = await sendToCustomerPortal(dispatch);
        //history.push('/')
        console.log('TEST response:', response);
      } catch (error) {
        console.log('Error: testinguser-> sendToCheckout ' ,error);
      }
    } 

    const logOut = () =>{
        //e.preventDefault();
        setTimeout(function(){
            setLoadState(true);
        }, 2000);
        logout(dispatch);
    } 

    //console.log('Header/localstorage: ', localStorage)
    return (
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
        <div className="container position-relative">
          <a className="navbar-brand" href="/">
            <img className="navbar-brand-regular logo-white"   src="/img/logo-white.png" alt="brand-logo" />
            <img className="navbar-brand-sticky logo-purple " src="/img/logo.png" alt="sticky brand-logo" />
          </a>
          <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>

          <div className="navbar-inner ">
            {/*  Mobile Menu Toggler */}
            <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <nav>
              <ul className="navbar-nav menu-font" id="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link " href="/">Inicio</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link " href="/about-us">Nosotras</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/asesoria">Asesoría</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/pricing">Precios</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/contact">Contacto</a>
                </li>


                {isLoggedIn ? 
                [
                    <li className="nav-item">
                        <a onClick ={sendToPortal} className="nav-link " href="#">Pagos</a>
                    </li>,
                    <li className="nav-item">
                        <a onClick = {logOut} className="nav-link " href="/">LogOut</a>
                    </li>
                ]
              : 
              <li className="nav-item">
              <a className="nav-link" href="/signup">Iniciar sesión</a>
            </li>
                }  
              </ul>
            </nav>
          </div>
          <LoadingSection 
            loadState = {loadState}
            handleClose={handleClose}
            loadHeader = {loadHeader}
            loadMessage = {loadMessage}
            loadButtonText = {loadButtonText}
            loadButtonLink ={loadButtonLink}
            />

        </div>
    </header>
    );
  
}

export default Header;