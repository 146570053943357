import Header from '../HeaderSection/Header';
import Breadcrumb from '../PrivacySection/Breadcrumb';
import PrivacySection from '../PrivacySection/PrivacySection';
import FooterSection from '../FooterSection/Footer';
import React from 'react';
import {  useContentState } from '../../context';

const Terms = props =>{
  const contentDetails = useContentState();
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={contentDetails.PrivacySectionContent.image} />
          <Breadcrumb title="Aviso de privacidad y confidencialidad" />
          <PrivacySection />
          <FooterSection />
        </div>
      </div>
    );
  
}

export default Terms;