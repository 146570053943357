import Header from '../HeaderSection/Header';
import Breadcrumb from '../TermsSection/Breadcrumb';
import TermsSection from '../TermsSection/TermsSection';
import FooterSection from '../FooterSection/Footer';
import React from 'react';
import {  useContentState } from '../../context';

const Terms = props =>{
  const contentDetails = useContentState();
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={contentDetails.TermsSectionContent.image} />
          <Breadcrumb title="Terminos de uso y condiciones" />
          <TermsSection />
          <FooterSection />
        </div>
      </div>
    );
  
}

export default Terms;