// import "./styles.css";
import { ScheduleMeeting } from "react-schedule-meeting";
import { useState, useEffect } from "react";
import axios from "axios";


const availableTimeslots = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14
].map((id) => {
  return {
    id,
    startTime: new Date(
      new Date(new Date().setDate(new Date().getDate() + id)).setHours(
        9,
        0,
        0,
        0
      )
    ),
    endTime: new Date(
      new Date(new Date().setDate(new Date().getDate() + id)).setHours(
        17,
        0,
        0,
        0
      )
    )
  };
});

//creating function to load ip address from the API
const submit_data = async (first_name, last_name, start_date) => {
  const res = await axios.get("https://geolocation-db.com/json/");

  res.data.first_name = first_name;
  res.data.last_name = last_name;
  res.data.start_date = start_date.toString();
  console.log(res.data.start_date);


};


const Schedule = (props) =>  {
  const [first_name, set_first_name] = useState("anonymous");
  const [last_name, set_last_name] = useState("anonymous");
  const [start_date, set_start_date] = useState(new Date());
  const [info_selected_active, set_info_selected_active] = useState(props);

  useEffect(() => {
    submit_data(first_name, last_name, start_date);
    set_info_selected_active(false);
  }, []);

  return (
    <div className="App" id="schedule">
 
      <ScheduleMeeting
        borderRadius={10}
        primaryColor="#3f5b85"
        eventDurationInMinutes={30}
        availableTimeslots={availableTimeslots}
        onStartTimeSelect={(e) => {
          set_start_date(e.startTime);
          set_info_selected_active(true);
        }}
      />
    </div>
  );
}
export default Schedule;