import React , {useEffect, useState} from 'react';
import Header from '../HeaderSection/Header';
import {useContentState } from '../../context';

const Forgot = props =>{

    const [loadState, setLoadState] = useState(false);
    const [loadMessage, setLoadMessage] = useState('Creando tu espacio seguro ...')
    const [loadHeader, setLoadHeader] = useState('Cargando')
    const [loadButtonText, setLoadButtonText] = useState('')
    const [loadButtonLink, setLoadButtonLink] = useState('/login')
    const contentDetails = useContentState();

        return (
            <div className="inner inner-pages">
                <div className="main">
                <Header 
                    imageData={"/img/logo-white.png"} 
                    setLoadState={setLoadState} 
                    loadState ={loadState}
                    loadMessage =  {loadMessage}
                    loadHeader = {loadHeader} 
                    loadButtonText={loadButtonText}
                    loadButtonLink = {loadButtonLink
                }/>    \
                    <section className="section welcome-area bg-overlay h-100vh ptb_100">
                        <div className="container h-100">
                            <div className="row align-items-center justify-content-center h-100">
                                <div className="col-12 col-md-10 col-lg-8">
                                    <div className="subscribe-content text-center">
                                        <h1 className="text-white">{contentDetails.ForgotSection.heading}</h1>
                                        <p className="text-white my-4">{contentDetails.ForgotSection.heading}</p>
                                        {/* Subscribe Form */}
                                        <form className="subscribe-form">
                                            <div className="form-group">
                                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your email" />
                                            </div>
                                            <button type="submit" className="btn btn-lg btn-block">{contentDetails.ForgotSection.heading}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    
}

export default Forgot;