import Header from '../HeaderSection/Header';
import Breadcrumb from '../Filesystem/Breadcrumb';
import FaqSection from '../FaqSection/FaqTwo';
import ScheduleInvite from '../CompletedSection/ScheduleInvite';
import ContactSection from '../ContactSection/Contact';
import FooterSection from '../FooterSection/Footer';
import React from 'react';
import {  useContentState } from '../../context';

const Faq = props =>{
  const contentDetails = useContentState();
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={contentDetails.FaqSectionContent.image} />
          <Breadcrumb title="FAQ" />
          <FaqSection />
          <ScheduleInvite />
          <ContactSection />
          <FooterSection />
        </div>
      </div>
    );
  
}

export default Faq;