import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
//import ThemeTwo from "../themes/theme-two";
//import ThemeThree from '../themes/theme-three';
//import ThemeFour from "../themes/theme-four";
//import ThemeFive from "../themes/theme-five";
//import ThemeSix from "../themes/theme-six";
//import BlogTwoColumn from "../components/Blogs/BlogTwoColumn";
//import BlogThreeColumn from "../components/Blogs/BlogThreeColumn";
//import BlogLeftSidebar from "../components/Blogs/BlogLeftSidebar";
//import BlogRightSidebar from "../components/Blogs/BlogRightSidebar";
//import BlogDetailsLeftSidebar from "../components/Blogs/BlogDetailsLeftSidebar";
//import BlogDetailsRightSidebar from "../components/Blogs/BlogDetailsRightSidebar";
//import Reviews from "../components/ReviewSection/Reviews";
import Pricing from "../components/PricingSection/Pricing";
import PaymentCompleted from "../components/CompletedSection/PaymentCompleted";
import PaymentError from "../components/CompletedSection/PaymentError";
import ContactCompleted from "../components/CompletedSection/ContactCompleted";

import AsesoriaCompleted from "../components/CompletedSection/AsesoriaCompleted";
import EventSubscribe from "../components/EventsSection/EventSubscribe";
import AboutUs from "../components/InnerSection/AboutUs";
import Login from "../components/Accounts/Login";
import Signup from "../components/Accounts/Signup";
import Forgot from "../components/Accounts/Forgot";
import Faq from "../components/FaqSection/Faq";
import ErrorPage from "../components/ErrorPage/404";
import ContactPage from "../components/ContactSection/ContactPage";
import AsesoriaFormPage from "../components/AsesoriaFormSection/AsesoriaFormPage";
import ProfileFormPage from "../components/ProfileFormSection/ProfileFormPage";
import CirculoFormPage from "../components/CirculoFormSection/CirculoFormPage";
import BlogFormPage from "../components/BlogFormSection/BlogFormPage";

import Terms from "../components/TermsSection/Terms";
import Privacy from "../components/PrivacySection/Privacy";
import BlogIndex from "../components/Filesystem/BlogIndex";
import BlogProfile from "../components/Filesystem/BlogProfile";
import PsychologistsIndex from "../components/Filesystem/PsychologistsIndex";
import PsychologistProfile from "../components/Filesystem/PsychologistProfile";
import CirculoIndex from "../components/Filesystem/CirculoIndex";
import CirculoProfile from "../components/Filesystem/CirculoProfile";

class MyRouts extends React.Component {

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeOne} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/faq" component={Faq} />
            <Route path="/error-page" component={ErrorPage} />
            <Route path="/contact" component={ContactPage} />

            <Route path="/asesoria" component={AsesoriaFormPage} />
            <Route path="/profile-form" component={ProfileFormPage} />
            <Route path="/circulo-de-mujeres-form" component={CirculoFormPage} />
            <Route path="/blog-form" component={BlogFormPage} />

            <Route path="/privacy" component={Privacy} />
            <Route path="/terms-and-conditions" component={Terms} />
            <Route path="/pago-completado" component={PaymentCompleted} />
            <Route path="/pago-cancelado" component={PaymentError} />
            <Route path="/mensaje-enviado" component={ContactCompleted} />
            <Route path="/asesoria-completada" component={AsesoriaCompleted} />
            <Route path="/es" component={EventSubscribe} />
            
            <Route exact path="/blog" component={BlogIndex}/> 
            <Route path="/blog/:blogPath" component={BlogProfile}/> 

            <Route exact path="/psicologas" component={PsychologistsIndex}/> 
            <Route path="/psicologas/:path_psicologa" component={PsychologistProfile}/> 

            <Route exact path="/circulos-de-mujeres" component={CirculoIndex}/> 
            <Route path="/circulos-de-mujeres/:path_circulo" component={CirculoProfile}/> 


          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;