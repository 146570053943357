import React , {useEffect, useState} from 'react';

const PsychologistsDetails = ({
    setPsychologistData,
    psychologistData
    } )=> {
        const [isLoaded, setLoaded] = useState(false);

        useEffect(() => {
            setLoaded( psychologistData);
          }, [isLoaded]);


        return (
            <div>
                {/* Single Blog Details */}
                <article className="single-blog-details">
                    {/* Blog Thumb */}
                    <div className="profile-thumb">
                        <a href="/#"><img src={psychologistData[0].data().profilepicture} alt="" /></a>
                    </div>


                    {/* Blog Content */}
                    <div className="blog-content sApp-blog">
                        {/* Meta Info */}
                        <div className="meta-info d-flex flex-wrap align-items-center py-2">

                            {/* Blog Share */}
                            <div className="blog-share ml-auto d-none d-sm-block">
                                {/*** / Social Icons 
                                <div className="social-icons d-flex justify-content-center">
                                    {psychologistData[0].data().iconList.map((item, idx) => {
                                        return(
                                            <a key={`bdi_${idx}`} className={item.link} href="/#">
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        );
                                    })}
                                </div>
                                {***/}
                            </div>
                        </div>
                        {/* Blog Details */}




                        <div className="blog-details">
                            <h3 className="blog-title py-3"><a href="/#">{"¿Qué le dirías a alguien que quiere comenzar su proceso psicoterapéutico?"}</a></h3>
                            <p className="d-none d-sm-block">{psychologistData[0].data().question1}</p>
                            <blockquote className="blockquote px-4 py-3 my-3 ml-4">
                                <p className="d-none d-sm-block color-purple-bold">{psychologistData[0].data().quote}</p>
                            </blockquote>
                        </div>

                        <div className="blog-details">
                            <h3 className="blog-title py-3"><a href="/#">{"Formación Académica"}</a></h3>
                            <p className="d-none d-sm-block">{psychologistData[0].data().academic_information}</p>

                        </div>

                    </div>
                    {/* Blog Comments */}
                    <div className="blog-comments">

                        
                    {/* Certification Verified */}
                    {psychologistData[0].data().certificationVerified['code'] ? 
                        [
                        <div className="admin media py-4 mt-4">
                            {/* Admin Thumb */}
                            <div className="avatar-lg">
                                <img className="" src={"https://firebasestorage.googleapis.com/v0/b/amaruapp-d9102.appspot.com/o/amaru-certificacion-verification.png?alt=media&token=2b0748ab-06e2-4718-883f-7e301b2af71f"} alt="" />
                            </div>
                            
                            <div className="admin-content media-body pl-3 pl-sm-4">
                                <h4 className="admin-name mb-2"><a href="/#">{'Psicológa certificada por Amaru'}</a></h4>
                                <p>{'Garantía Amaru, tu psicóloga forma parte del programa de garantía Amaru. Te aseguramos encontrar tu psicóloga ideal'}</p>
                            </div>
                        </div>
                        ]
                    : 
                    null
                        }  

                        
                    {/* Certification Garantia */}
                        {psychologistData[0].data().certificationGuarantee['code'] ? 
                        [
                        <div className="admin media py-4 mt-4">
                            {/* Admin Thumb */}
                            <div className="avatar-lg">
                                <img className="" src={"https://firebasestorage.googleapis.com/v0/b/amaruapp-d9102.appspot.com/o/amaru-certificacion-garantia.png?alt=media&token=6a784be0-f60e-4bd9-aa8a-4d9be66a2da6"} alt="" />
                            </div>
                            
                            <div className="admin-content media-body pl-3 pl-sm-4">
                                <h4 className="admin-name mb-2"><a href="/#">{'Psicológa certificada por Amaru'}</a></h4>
                                <p>{'Garantía Amaru, tu psicóloga forma parte del programa de garantía Amaru. Te aseguramos encontrar tu psicóloga ideal'}</p>
                            </div>
                        </div>
                        ]
                    : 
                    null
                        }  
                    {/* Certification Genero */}

                        {psychologistData[0].data().certificationGender['code'] ? 
                        [
                            <div className="admin media py-4 mt-4">
                            {/* Admin Thumb */}
                            <div className="avatar-lg">
                                <img className="" src={"https://firebasestorage.googleapis.com/v0/b/amaruapp-d9102.appspot.com/o/amaru-certificacion-genero.png?alt=media&token=4bace25e-b4c6-4f07-8a43-f187c798ee56"} alt="" />  
                            </div>
                            {/* Admin Content */}
                            <div className="admin-content media-body pl-3 pl-sm-4">
                                <h4 className="admin-name mb-2"><a href="/#">{'Perspectiva de género'}</a></h4>
                                <p>{psychologistData[0].data().name +  ' ha desmotrado su compromiso y conocimiento en el área de perspectiva de género'}</p>
                            </div>
                        </div>
                        ]
                    : 
                    null
                        }  

                    {/* Certification Grupal */}
                    {psychologistData[0].data().certificationDiverse['code'] ? 
                            [
                                <div className="admin media py-4 mt-4">
                                {/* Admin Thumb */}
                                <div className="avatar-lg">
                                    <img className="" src={"https://firebasestorage.googleapis.com/v0/b/amaruapp-d9102.appspot.com/o/amaru-certificacion-terapiagrupal.png?alt=media&token=81e9eaca-0dcf-4df4-8fb5-679e9641c1e2"} alt="" />  
                                </div>
                                {/* Admin Content */}
                                <div className="admin-content media-body pl-3 pl-sm-4">
                                    <h4 className="admin-name mb-2"><a href="/#">{'Terapia grupal y de pareja'}</a></h4>
                                    <p>{ 'Por su experiencia en el área de terapia grupal y de pareja '  + psychologistData[0].data().name +  ' forma parte del programa Comunidad Amaru'}</p>
                                </div>
                            </div>
                            ]
                    : 
                    null
                        }  

                    {psychologistData[0].data().certificationSocialimpact['code'] ? 
                            [
                                <div className="admin media py-4 mt-4">
                                {/* Admin Thumb */}
                                <div className="avatar-lg">
                                    <img className="" src={"https://firebasestorage.googleapis.com/v0/b/amaruapp-d9102.appspot.com/o/amaru-certificacion-programasocial.png?alt=media&token=b27a32a6-3f64-4f32-9657-12910af91084"} alt="" />  
                                </div>
                                {/* Admin Content */}
                                <div className="admin-content media-body pl-3 pl-sm-4">
                                    <h4 className="admin-name mb-2"><a href="/#">{'Impacto Social'}</a></h4>
                                    <p>{ psychologistData[0].data().name +  '  forma parte de la comunidad Amaru que bsuca brindar ayuda sin ningún costo a personas en situaciones de vulnerabilidad. ' + psychologistData[0].data().name + ' es la verdadera MVP <3 '}</p>
                                </div>
                            </div>
                            ]
                    : 
                    null
                        }  


                    </div>

                </article>
            </div>
        );
    }
export default PsychologistsDetails;