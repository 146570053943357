import React , {useEffect, useState} from 'react';

const PsychologistSidebar = ({
    setPsychologistData,
    psychologistData
    } )=> {
        return (
            <div>
                <aside className="sidebar">

                    {/* Single Widget */}
                    <div className="single-widget">
                        {/* Category Widget */}
                        <div className="accordions widget catagory-widget" id="cat-accordion">
                        <div className="single-accordion blog-accordion">
                            <h5>
                            <a role="button" className="collapse show text-uppercase d-block p-3" data-toggle="collapse" href="#accordion1">
                                Psic. {psychologistData[0].data().name}  {psychologistData[0].data().apellidoPaterno} {psychologistData[0].data().apellidoMaterno} 
                            </a>
                            </h5>

                            {/* General information Widget Content */}
                            <div id="accordion1" className="accordion-content widget-content collapse show" data-parent="#cat-accordion">
                                {/* Category Widget Items */}
                                <ul className="widget-items">
                                    <li ><a href="/#" className="d-flex p-3"><strong>{"Maestría"}</strong><span className="ml-auto ">{psychologistData[0].data().master['name']}</span></a></li>
                                    <li ><a href="/#" className="d-flex p-3"><strong>{"Especialidad"}</strong><span className="ml-auto">{psychologistData[0].data().mainspecialization['name']}</span></a></li>
                                    <li ><a href="/#" className="d-flex p-3"><strong>{"Cédula"}</strong><span className="ml-auto">{psychologistData[0].data().cedula}</span></a></li>

                                </ul>
                            </div>

                        </div>
                        </div>
                    </div>
                   

                    {/* Single Widget */}
                    <div className="single-widget">
                        {/* Category Widget */}
                        <div className="accordions widget catagory-widget" id="cat-accordion">
                        <div className="single-accordion blog-accordion">
                            <h5>
                            <a role="button" className="collapse show text-uppercase d-block p-3" data-toggle="collapse" href="#accordion1">{'Áreas de especialización'}
                            </a>
                            </h5>

                            {/* General information Widget Content */}
                            <div id="accordion1" className="accordion-content widget-content collapse show" data-parent="#cat-accordion">
                                {/* Category Widget Items */}
                                <ul className="widget-items">
                                    {psychologistData[0].data().specialization_areas.map((item, idx) => {
                                        return(
                                            <li key={`wdo_${idx}`}><a href="/#" className="d-flex p-3"><span>{item}</span></a></li>
                                        );
                                    })}
                                </ul>
                            </div>

                            

                        </div>
                        {/* Single Widget */}
                        <div className=" my-5 schedule-text ">
                            {/* Comments Title */}
                            <a href='#schedule' className="comments-title text-uppercase text-primary  color-purple-bold">{"Ver horarios disponibles"}</a>
                            {/* Single Comments */}
                        </div>

                        </div>
                    </div>


                </aside>
            </div>
        );
}

export default PsychologistSidebar;