import React  from 'react';
import {useContentState } from '../../context';

const Team = props => {
  const contentDetails = useContentState();
    return (
      <section className="section team-area team-style-two overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            {/* Section Heading */}
            <div className="section-heading text-center">
            <h2>{contentDetails.TeamSectionContent.heading}</h2>
            <p className="d-none d-sm-block mt-4">{contentDetails.TeamSectionContent.headingText}</p>
            <p className="d-block d-sm-none mt-4">{contentDetails.TeamSectionContent.headingTexttwo}</p>
            </div>
          </div>
          </div>
          <div className="row">
            {contentDetails.TeamSectionContent.teamData.map((item, idx) => {
              return(
                <div key={`t_${idx}`} className="col-12">
                  {/* Single Team */}
                  <div className="single-team text-center radius-100 overflow-hidden m-2 m-lg-0">
                  {/* Team Thumb */}
                  <div className="team-thumb radius-100 d-inline-block position-relative overflow-hidden">
                        <img src={item.image} alt="" />
                        {/* Team Overlay */}
                        <div className="team-overlay radius-100">
                            <h4 className="team-name text-white">{item.title}</h4>
                            <h5 className="team-post text-white mt-2 mb-3">{item.teamPost}</h5>
                            {/* Team Icons */}
                            <div className="team-icons">
                            <a className="p-2 text-white" href="https://www.linkedin.com/company/amarumx"><i className={item.iconClass} />asesoria@amaru.mx</a>
                            </div>
                        </div>
                  </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  
}

export default Team;